import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Image,
  Card,
  Collapse,
  Button,
  Divider,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import purseLogo from "../images/Group.png";
import aboutPic from "../images/phone.png";
import codeSnap from "../images/Appscreenshot.png";
import stepOnePic from "../images/step1.png";
import stepTwoPic from "../images/step2.png";
import stepThreePic from "../images/step3.png";
import stepFourPic from "../images/step4.png";
import { CheckCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import MenuModal from "../components/MenuModal";
import Footer from "../components/Footer";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import FootBack from "../images/footer_back.svg";
import topBack from "../images/top_back.svg";
import appleLogo from "../images/Apple_store_logo.png";
import playStoreLogo from "../images/google_playStore_logo.png";
import scanHand from "../images/scan_hand.png";

const items = [
  {
    key: "1",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>
        {" "}
        Why should I use Purse?
      </div>
    ),
    children:
      "Purse helps you accept contactless payment from customers without hassle ",
  },
  {
    key: "2",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>
        Is there a free trial?
      </div>
    ),
    children: "Yes, we offer a 7 day free trial ",
  },
  {
    key: "3",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>
        How much do you charge per transaction?
      </div>
    ),
    children: "1.5% on every transaction from 1000 naira upward",
  },
  {
    key: "4",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>
        How do I get my QR code sticker in my store?
      </div>
    ),
    children:
      "Enter the QR code button in your dashboard, click on request for QR code, enter quantity and place order",
  },
  {
    key: "5",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>
        How long does it take to receive my QR code?
      </div>
    ),
    children:
      "48hours only within Lagos for now. We look forward to expanding to other cities Nationwide.",
  },
  {
    key: "6",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>Is purse registered?</div>
    ),
    children: "Yes, purse is registered with the CAC",
  },
  {
    key: "7",
    label: (
      <div style={{ fontWeight: 700, color: "#897" }}>
        Is it safe using Purse?
      </div>
    ),
    children: "Yes, your data is safe as it is end-to-end encrypted",
  },
];
const { Paragraph, Text } = Typography;
function HomePage() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [wideScreen, setWideScreen] = useState(true);
  const [open, setOpen] = useState(false);

  const gridStyle = {
    width: wideScreen ? "25%" : "100%",
    textAlign: "center",
    minWidth: "300px",
    cursor: "pointer",
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 680) {
      setWideScreen(false);
    } else {
      setWideScreen(true);
    }
  }, [screenWidth]);
  return (
    <div className="App">
      {/* this is the top nav */}
      <div
        style={{
          backgroundImage: `url(${topBack})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          padding: "1rem 1rem",
        }}
      >
        <div id="Home">
          <Row className="top-menu-container" align="middle">
            <Col md={6} span={6}>
              <img
                src={purseLogo}
                alt="purse-logo"
                height={40}
                style={{ margin: "7px" }}
              />
            </Col>
            <Col md={18} span={18} style={{ textAlign: "right" }}>
              {wideScreen ? (
                <div className="top-menu">
                  <Space size={20}>
                    <li>
                      <Link activeClass="active" smooth spy to="Home">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link activeClass="active" smooth spy to="About">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link activeClass="active" smooth spy to="Benefits">
                        Benefits
                      </Link>
                    </li>
                    <li>
                      <Link activeClass="active" smooth spy to="How it works">
                        How it works
                      </Link>
                    </li>
                    <li>
                      <Link activeClass="active" smooth spy to="FAQs">
                        FAQs
                      </Link>
                    </li>
                    <Button className="black" size="large" type="primary">
                      <a href="https://www.merchant-pursepay.com.ng">
                        Get Started{" "}
                      </a>
                    </Button>
                  </Space>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "end",
                    display: "flex",
                    alignItems: "end",
                    fontSize: "25px",
                    color: "#fff",
                  }}
                >
                  <MenuOutlined onClick={() => handleOpen()} />
                </div>
              )}
              <MenuModal open={open} handleClose={handleClose} />
            </Col>
          </Row>
        </div>
        {/* the top nava ends here */}
        {/* The Home hero starts from here */}
        <motion.div
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          style={{
            padding: wideScreen && screenWidth > 1122 ? "7% 5%" : "5rem 5%",
            opacity: 0.4,
          }}
        >
          <Row align="middle">
            <Col md={12}>
              <Paragraph
                style={{
                  fontSize: "60px",
                  margin: "0.7rem 0",
                  lineHeight: 0.9,
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                Payments made seamless <br />
              </Paragraph>
              <Paragraph
                style={{
                  fontSize: "18px",
                  margin: "1.5rem 0",
                  fontWeight: 500,
                  lineHeight: 1.2,
                  color: "#f1f1f1",
                  width: "75%",
                }}
              >
                Accept seamless payment from customers in-stores in just less
                than 10 seconds.
              </Paragraph>
              <Paragraph>
                <Space>
                  <Button className="get-stt black" type="primary" size="large">
                    <a
                      href="https://www.merchant-pursepay.com.ng"
                      style={{ color: "#fff" }}
                    >
                      Get Started{" "}
                    </a>
                  </Button>
                  <Button
                    className="get-stt orange"
                    type="primary"
                    size="large"
                    icon={<ArrowRightOutlined />}
                  >
                    Learn More
                  </Button>
                </Space>
              </Paragraph>
            </Col>
            <Col md={12}></Col>
          </Row>
        </motion.div>
      </div>
      {/* The Home hero ends from here */}
      {/* The About page starts here */}
      <div
        style={{
          // backgroundColor: "#F1f1f1",
          padding: "5% 5%",
        }}
        id="About"
      >
        <Divider style={{ height: "20px", border: "#254" }}>
          <Paragraph
            style={{
              fontSize: "32px",
              color: "#0C6F8D",
              fontWeight: 700,
              display: "inline",
            }}
          >
            ABOUT PURSE
          </Paragraph>
        </Divider>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Paragraph
            style={{
              textAlign: "center",
              margin: wideScreen ? "0 20%" : "0 2%",
              fontSize: "16px",
            }}
          >
            Welcome to purSe your one stop payment solution. PurSe is a QR base
            e-wallet solution that helps merchants, like you, accept contactless
            payment from customers without cash or debit card. PurSe provides
            seamless payment transactions without hassles
          </Paragraph>
        </div>

        <Row style={{ margin: "2rem 0" }}>
          <Col md={12}>
            <Image
              src={aboutPic}
              alt="about-purse"
              preview={false}
              width="auto"
            />
          </Col>
          <Col md={12} style={{ padding: "1rem" }}>
            <Paragraph style={{ fontSize: "20px", fontWeight: 600 }}>
              Features of Purse
            </Paragraph>
            <Paragraph>
              <Space direction="vertical" size={[30, 40]}>
                {[
                  { name: "Zero onboarding fee", type: 1 },
                  { name: "Seamless payment collection", type: 2 },
                  { name: "Instant credit alert", type: 1 },
                  { name: "Customize QR payment terminal", type: 2 },
                ].map((x) => (
                  <Space
                    style={{
                      backgroundColor: x.type === 1 ? "#FF5733" : "#545657",
                      padding: "5px 15px 5px 10px",
                      borderRadius: "15px",
                      color: "#fff",
                    }}
                    key={x.name}
                  >
                    <CheckCircleOutlined /> {x.name}
                  </Space>
                ))}
              </Space>
            </Paragraph>
          </Col>
        </Row>
      </div>
      {/* The about page ends here */}

      {/* The Benefits page start from here */}
      <div
        style={{
          padding: "9% 5%",
        }}
        id="Benefits"
      >
        <Divider style={{ height: "20px", border: "#254" }}>
          <Paragraph
            style={{
              fontSize: "32px",
              color: "#0C6F8D",
              fontWeight: 700,
              display: "inline",
            }}
          >
            KEY BENEFITS
          </Paragraph>
        </Divider>
        <Row align="middle" style={{ margin: "3rem 0" }}>
          <Col md={12}>
            <Paragraph>
              <Space
                direction="vertical"
                size={15}
                style={{ width: "70%", minWidth: "320px" }}
              >
                {[
                  { name: "Approach and attract more customers.", type: 1 },
                  { name: "Low transaction fee.", type: 2 },
                  { name: "Simple, cost effective setup.", type: 1 },
                  { name: "Accept faster payment.", type: 2 },
                  { name: "Security coverage.", type: 1 },
                  {
                    name: "Bridge the gap between face-to-face and Digital.",
                    type: 2,
                  },
                  { name: "No change, No wahala!", type: 1 },
                ].map((x) => (
                  <Space
                    style={{
                      backgroundColor: x.type === 1 ? "#545657" : "#FF5733",
                      padding: "15px 20px 15px 20px",
                      float: x.type === 2 ? "right" : "left",
                      color: "#f1fff1",
                      borderBottom: "1px solid #545657",
                      boxShadow: "2px 2px 6px #545657",
                      borderRadius: "5px",
                    }}
                    key={x.name}
                  >
                    <CheckCircleOutlined /> {x.name}
                  </Space>
                ))}
              </Space>
            </Paragraph>
          </Col>
          <Col md={12} style={{ textAlign: "center" }}>
            <Image
              src={codeSnap}
              alt="code-scan-purse"
              width="90%"
              preview={false}
              style={{
                boxShadow: "2px 2px 10px #545657",
                borderRadius: "30px",
              }}
            />
          </Col>
        </Row>
      </div>
      {/* The benefits page end from here */}

      {/* App store logogs */}
      <div
        style={{
          padding: "14% 5%",
          background: "#0C6F8D",
        }}
      >
        <Row>
          <Col md={14}>
            <Paragraph
              style={{
                fontSize: "38px",
                padding: "0 1.5rem",
                lineHeight: 1.2,
                color: "#f1f1f1",
              }}
            >
              Purse works right from your device, helping you avoid handling
              excessive cards or exchanging cash.
            </Paragraph>
            <Paragraph
              style={{
                padding: "0 1.5rem",
                margin: "0",
                lineHeight: 1.2,
                color: "#f0a80cdc",
                fontWeight: 700,
                fontSize: "38px",
              }}
            >
              Simply scan and go...
            </Paragraph>

            <Paragraph style={{ padding: "1.5rem 1.5rem", margin: "0" }}>
              <Space>
                <Image
                  src={playStoreLogo}
                  preview={false}
                  alt="playStore logo"
                  width="14rem"
                  style={{ cursor: "pointer" }}
                />
                <Image
                  src={appleLogo}
                  preview={false}
                  alt="apple logo"
                  width="14rem"
                  style={{ cursor: "pointer" }}
                />
              </Space>
            </Paragraph>
          </Col>
          <Col md={10}>
            <Image
              src={scanHand}
              alt="scan hand"
              preview={false}
              style={{ borderRadius: "15px", boxShadow: "2px 2px 10px #000" }}
            />
          </Col>
        </Row>
      </div>
      {/* The app store section ends from here */}
      {/* The procedure page starts from here */}
      <div
        style={{
          backgroundColor: "#fff",
          padding: "6% 5%",
          textAlign: "center",
        }}
        id="How it works"
      >
        <Divider style={{ height: "20px", border: "#254" }}>
          <Paragraph
            style={{
              fontSize: "32px",
              color: "#0C6F8D",
              fontWeight: 700,
              display: "inline",
            }}
          >
            HOW PURSE WORKS
          </Paragraph>
        </Divider>
        <Paragraph>
          <Text style={{ fontSize: "16px" }}>4 Simple Steps</Text>
        </Paragraph>
        <Card>
          <Card.Grid style={gridStyle}>
            <Image
              src={stepOnePic}
              alt="purse-feature"
              preview={false}
              width="70%"
            />
            <Paragraph
              style={{ fontSize: "16px", fontWeight: wideScreen ? 200 : 400 }}
            >
              Merchant enter amount for Goods and Services.
            </Paragraph>
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Image
              src={stepTwoPic}
              alt="purse-feature"
              preview={false}
              width="70%"
            />
            <Paragraph style={{ fontSize: "16px", fontWeight: 200 }}>
              Present your purSe QR code to customers on counter.
            </Paragraph>
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Image
              src={stepThreePic}
              alt="purse-feature"
              preview={false}
              width="70%"
            />
            <Paragraph style={{ fontSize: "16px", fontWeight: 200 }}>
              Customer scans the QR code with purSe app.
            </Paragraph>
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Image
              src={stepFourPic}
              alt="purse-feature"
              preview={false}
              width="70%"
            />
            <Paragraph style={{ fontSize: "16px", fontWeight: 200 }}>
              Customer confirms and authenticates payment with passcode.
            </Paragraph>
          </Card.Grid>
        </Card>
      </div>
      {/* The Procedure page ends here */}
      {/* The FAQs start from here */}
      <div
        style={{
          backgroundColor: "#F1f1f1",
          padding: "6% 5%",
        }}
        id="FAQs"
      >
        <Divider style={{ height: "20px", border: "#254" }}>
          <Paragraph
            style={{
              fontSize: "32px",
              color: "#0C6F8D",
              fontWeight: 700,
              display: "inline",
            }}
          >
            FAQs
          </Paragraph>
        </Divider>
        <Paragraph style={{ textAlign: "center" }}>
          <Text>
            Everything you need to know about the product and billing.
          </Text>
        </Paragraph>
        <Collapse items={items} bordered={false} defaultActiveKey={["1"]} />
      </div>
      {/* The Faqs end from here */}
      <div
        style={{
          backgroundImage: `url(${FootBack})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          paddingTop: "15rem",
        }}
      >
        <Footer wideScreen={wideScreen} />
      </div>
    </div>
  );
}

export default HomePage;
