import { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";

function Preliminary() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#141414",
            fontFamily: "sans-serif",
            colorText: "#424242",
            fontSizeIcon: "18px",
          },
        }}
      >
        <Routes>
          <Route path="/" element={<HomePage screenWidth={screenWidth} />} />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default Preliminary;
