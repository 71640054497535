import { Modal } from "antd";
import { Link } from "react-scroll";
function MenuModal({ handleClose, open }) {
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      width="80%"
      title="Navigate to"
    >
      <div className="small-screen-menu">
        <li>
          <Link
            activeClass="active"
            smooth
            spy
            to="Home"
            onClick={() => handleClose()}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            smooth
            spy
            to="About"
            onClick={() => handleClose()}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            smooth
            spy
            to="Benefits"
            onClick={() => handleClose()}
          >
            Benefits
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            smooth
            spy
            to="How it works"
            onClick={() => handleClose()}
          >
            How it works
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            smooth
            spy
            to="FAQs"
            onClick={() => handleClose()}
          >
            FAQs
          </Link>
        </li>
      </div>
    </Modal>
  );
}

export default MenuModal;
