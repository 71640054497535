import { Image, Space, Button, Input } from "antd";
import purseLogo from "../images/Group.png";
import {
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  LinkedinFilled,
  SkypeFilled,
} from "@ant-design/icons";

const gridContainer = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(360px, 1fr))",
  gap: "0 7rem",
  alignItems: "center",
  color: "#fff",
};
const gridCard = {
  padding: "20px",
};
function Footer({ wideScreen }) {
  return (
    <>
      <div style={gridContainer}>
        <div style={{ padding: "1rem 0 1rem 3rem", ...gridCard }}>
          <Image src={purseLogo} alt="purse-logo" height={50} preview={false} />
          <p style={{ padding: "1rem 0rem" }}>
            <b>&copy; 2023 purSe. All rights reserved </b>
          </p>
        </div>
        <div style={gridCard}>
          <p style={{ fontSize: "18px", fontWeight: 600 }}>
            Enjoy seamless transactions without hassle.
          </p>
          <p>Email: info@pursepay.com.ng</p>
        </div>
        <div style={gridCard}>
          <p style={{ padding: "1rem 0" }}>Stay updated about our products</p>
          <p>
            <Input placeholder="Enter email address" />
          </p>
          <p style={{ textAlign: "right" }}>
            <Button type="primary">Subscribe</Button>
          </p>
          <Space size={10}>
            <FacebookFilled style={{ fontSize: "20px", color: "#f1f1f1" }} />
            <TwitterSquareFilled
              style={{ fontSize: "20px", color: "#f1f1f1" }}
            />
            <InstagramFilled style={{ fontSize: "20px", color: "#f1f1f1" }} />
            <LinkedinFilled style={{ fontSize: "20px", color: "#f1f1f1" }} />
            <SkypeFilled style={{ fontSize: "20px", color: "#f1f1f1" }} />
          </Space>
        </div>
      </div>
      <div
        style={{
          padding: "1.5rem 0",
          textAlign: "center",
          borderTop: "3px solid #f1f1f1",
          color: "#fff",
        }}
      >
        A &copy; 2023 Copyright by purSe. All Rights Reserved
      </div>
    </>
  );
}

export default Footer;
